.react-datepicker__header  {
@apply bg-c-light-blue-2 !important
}

.react-datepicker{
 @apply  border-transparent bg-c-light-blue-2 text-sm font-bold font-poppins shadow-md shadow-gray-400 h-80
} 

.react-datepicker__month-container{
@apply bg-c-light-blue-2
}
.react-datepicker__triangle::before{
  border-top-color: rgb(221,232,242) !important;
  border-bottom-color: rgb(221,232,242) !important;
  }
.react-datepicker__triangle::after{
border-bottom-color: rgb(221,232,242) !important;
border-top-color: rgb(221,232,242) !important;
}


.react-datepicker__day-name{
  @apply text-c-dark-blue-2 text-xs font-bold font-poppins w-8 h-8
}
.react-datepicker__day{
  @apply text-c-dark-blue-2 text-xs font-bold font-poppins w-8 h-8 p-2
}

.react-datepicker-wrapper {
  width: 100% ;
}
.react-datepicker__day--today{
  @apply rounded-full
}
.react-datepicker__day--selected{
  @apply rounded-full text-white
}
.react-datepicker__day:hover{
  @apply rounded-full bg-white
}

/* Correct CSS with proper z-index */
/* .react-datepicker__input-container {
  position: relative;
  z-index: 0;
} */


/* .react-datepicker__input-container {
  width: inherit ;
} */

/* .react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.75em;
} */

.react-datepicker__view-calendar-icon input {
  /* padding: 20px 10px 5px 25px; */

  padding-left: 32px;


}


.react-datepicker__calendar-icon {
  width: 18px;
  height: 17px;
  /* vertical-align: 0em; */
  margin-left: 4px;
  margin-top: 4px;
  margin-right: 4px;
  /* margin-left: 9px; */
}

