@tailwind base;
@tailwind components;
@tailwind utilities;
.react-datepicker__header {
  @apply bg-white border-none rounded-2xl !important;
}

.react-datepicker {
  @apply border-4 border-solid rounded-2xl !important;
}

.react-datepicker__day--disabled {
  @apply text-c-blue/50 hover:bg-transparent !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Following scroll bar styling is probably not the right way to style the table scroll bar. */
/* It applies to all divs. Need to rethink and do it the right way  */

body::-webkit-scrollbar {
  /* height: 10px; */
  /* background-color: rgba(27, 67, 115, 0.5); */
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
}

body::-webkit-scrollbar-track {
  /* background-color: rgba(27, 67, 115, 0.5); */
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #0f1b2e;
  border-radius: 5px;
  width: 10px;
  height: 50px;
}

div::-webkit-scrollbar {
  /* height: 10px; */
  /* background-color: rgba(27, 67, 115, 0.5); */
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
}

div::-webkit-scrollbar-track {
  /* background-color: rgba(27, 67, 115, 0.5); */
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
}

div::-webkit-scrollbar-thumb {
  background-color: #0f1b2e;
  border-radius: 5px;
  width: 10px;
  height: 50px;
}

#sideNavContainer::-webkit-scrollbar {
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
}

#sideNavContainer::-webkit-scrollbar-track {
  background-color: #0f1b2e;
  border-radius: 5px;
  width: 10px;
}

#sideNavContainer::-webkit-scrollbar-thumb {
  background-color: #7d96b1;
  border-radius: 5px;
  width: 10px;
  height: 50px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

#sideNavContainer::-webkit-scrollbar {
  width: 5px;
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}
.tooltip::after {
  content: '';
  position: absolute;
  top: 100%; /* Position it below the tooltip */
  left: 50%; /* Center it horizontally */
  margin-left: -5px; /* Adjust the horizontal position if needed */
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent; /* Arrow color */
}

.tab-bar {
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.resizer {
  cursor: col-resize;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent !important;
}

.text-pretty {
  text-wrap: pretty;
}
